<template>
  <div class="Blog">
      <br>
      <main id="main">
    <section class="breadcrumbs">
      <div class="container" data-aos="zoom-in">
        <ol>
          <li><router-link :to="{ name: 'Home', params: { userId: 123 }}" class="a" v-scroll-to="'#'">Home</router-link>
          <li>.</li>
        </ol>
        <h2 style="text-align:left">Blog</h2>
      </div>
    </section>
      <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-8 entries" v-if='currentBlog'>

      <div class='container'>
      <h2>{{ this.currentBlog[0].blogTitle }}</h2>
      <h4>Posted on: {{ new Date(this.currentBlog[0].blogDate).toLocaleString('en-us', { dateStyle: 'long' }) }}</h4>
      <img :src='this.currentBlog[0].blogCoverPhoto' alt='' class="center" style="height: 330px; width:auto;" />
      <div class='post-content' v-html='this.currentBlog[0].blogHTML'></div>

      </div>
          </div>
          <div class="col-lg-4">

            <div class="sidebar">

              <h3 style="display:none" class="sidebar-title">Buscar</h3>
              <div style="display:none" class="sidebar-item search-form">
                <form action="">
                  <input type="text">
                  <button type="submit"><i class="bi bi-search"></i></button>
                </form>
              </div><!-- End sidebar search formn-->

              <h3 style="display:none" class="sidebar-title">Categorias</h3>
              <div style="display:none" class="sidebar-item categories">
                <ul>
                  <li><a href="#">General <span>(25)</span></a></li>
                  <li><a href="#">Vulnerabilidades <span>(22)</span></a></li>
                  <li><a href="#">Procedimientos <span>(8)</span></a></li>
                  <li><a href="#">Ciberseguridad <span>(14)</span></a></li>
                </ul>
              </div><!-- End sidebar categories-->

              <h3 style="display:none" class="sidebar-title">Recent Posts</h3>
              <div style="display:none" class="sidebar-item recent-posts">
                <div class="post-item clearfix">
                  <img src="assets/img/blog/blog-recent-1.jpg" >
                  <h4><a href="#">Nihil blanditiis at in nihil autem</a></h4>
                  <time datetime="2020-01-01">Jan 1, 2020</time>
                </div>

                <div class="post-item clearfix">
                  <img src="assets/img/blog/blog-recent-2.jpg" alt="">
                  <h4><a href="#">Quidem autem et impedit</a></h4>
                  <time datetime="2020-01-01">Jan 1, 2020</time>
                </div>

                <div class="post-item clearfix">
                  <img src="assets/img/blog/blog-recent-3.jpg" alt="">
                  <h4><a href="#">Id quia et et ut maxime similique occaecati ut</a></h4>
                  <time datetime="2020-01-01">Jan 1, 2020</time>
                </div>

              </div><!-- End sidebar recent posts-->

              <h3 class="sidebar-title">Tags</h3>
              <div class="sidebar-item tags">
                <ul>
                  <li><a href="#">App</a></li>
                  <li><a href="#">IT</a></li>
                  <li><a href="#">Business</a></li>
                  <li><a href="#">Ciberseguridad</a></li>
                  <li><a href="#">Vulnerabilidades</a></li>
                  <li><a href="#">Disaster Recovery</a></li>
                </ul>
              </div><!-- End sidebar tags-->

              <div class="sidebar-item tags">
              <br>
              <img src="assets/img/favicon.webp" style="max-height: 100px;" class="center" alt="">
              </div>
            </div><!-- End sidebar -->
          </div>
          </div>
      </div>
      </section>
      </main>
  </div>
</template>

<script>
export default {
  name: 'ViewBlog',
  props: ['post'],
  data () {
    return {
      currentBlog: null
    }
  },
  created () {
  },
  async mounted () {
    console.error('Por buscar')
    console.error('Cierre')

    setTimeout(() => {
      var todoslospost = this.$store.state.blogPosts
      this.currentBlog = todoslospost.filter((post) => {
        return post.blogID === this.$route.params.blogid
      })
    }, 1500)
  }
}
</script>

<style lang="scss">
.post-view {
  h4 {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 24px;
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

</style>
